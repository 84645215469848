/* max-width: 1440px
   ----------------------------- */

@media screen and (max-width: 1440px) {

}

/* max-width: 1366px
   ----------------------------- */

@media screen and (max-width: 1366px) {

}

/* max-width: 1280px
   ----------------------------- */

@media screen and (max-width: 1280px) {

}

/* max-width: 1024px
   ----------------------------- */

@media screen and (max-width: 1024px) {
  .app-wrapper .menu__link{
    display: none;
  } 
  
}

/* max-width: 768px
   ----------------------------- */

@media screen and (max-width: 768px) {
  .menu-item, .menu-open-button{
    top: -15px;
  }
  
  .app-wrapper .sidebar__logo {
    margin-top: 0;
    margin-bottom: 40px;
    margin-left: 18px;
    height: auto;
    width: 66px;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    position: relative;
    top: 0;
    left: 0;
  }
  .app-wrapper .menu__header {
    margin-bottom: 25px;
    font-size: 18px;
  }
  .app-wrapper .menu__item {
    font-size: 14px;
  }
  .menu li:last-child .menu__item {
    margin-bottom: 0;
  }
  .app-wrapper .menu {
    min-height: initial;
  }
  .app-wrapper .sidebar {
    width: 135px;
  }
  .menu-item, .menu-open-button {
    margin-left: -40px;
  }
  .app-wrapper .ribbing__menu {
    position: relative;
    bottom: 0;
  }
  .app-wrapper .ribbing .ribbing-icon {
    display: none;
  }
  .app-wrapper .thumbnail__image {
    padding: 3px;
    border: 2px solid #fff;
    border-radius: 100%;
    margin: 0 auto;
    max-width: 45px;
  }
  .app-wrapper .ribbing__menu .ribbing__title {
    font-size: 10px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 17px;
    margin-top: 3px;
  }
  .app-wrapper .ribbing {
    display: flex;
    align-items: center;
  }
  .app-wrapper .ribbing__menu h2 {
    margin-bottom: 20px;
  }
  .app-wrapper .menu li span {
    left: 0;
    padding: 10px 17px;
  }
  .app-wrapper .menu li:after {
    left: 18px;
  }
  .sidebar--1 .menu li:first-child {
    display: none;
  }
  .names-wrapper {
    position: absolute;
    bottom: 19px;
    right: 19px;
  }

}

@media screen and (orientation:landscape)
{
  .portrait-alert {
    display: none;
  }
}
