.app-wrapper{
  height: 100vh;
  position: relative;
  background-size: cover;
  .sidebar{
    background: #26222d;
    width: 192px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    top: 0;
    left:0;
    z-index: 20;
    -webkit-box-shadow: 4px 0px 18px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 4px 0px 18px 0px rgba(0,0,0,0.2);
    box-shadow: 4px 0px 18px 0px rgba(0,0,0,0.2);
    &__link{
      position: absolute;
      top: 20px;
    }
    .swipeMenu{
        position: absolute;
        display: block;
        background-color: #c64916;
        height: 55px;
        width:20px;
        top: calc(50% - 23px);
        right: - 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all .4s;
        cursor: pointer;
      }
    &.last-menu-element{
      .swipeMenu{
        &:hover{
          opacity: .7s;
        }
        opacity: 1;
        transition: all .4s;
      }
    }
    &__logo{
      margin-top: 45px;
      margin-left: 42px;
      height: 42px;
      width: 90px;
      align-self: auto;
      position: fixed;
      top: 0;
      left: 0;
    }
    &--2{
      z-index: 19;
      left:-192px;
      opacity: 0;
      background: rgba(38, 34, 45, .95);
    }
    &--3{
      left: -192px;
      z-index: 18;
      opacity: 1;
      background: rgba(38, 34, 45, .90);
    }
    &--4{
      left: -192px;
      z-index: 17;
      opacity: 1;
      background: rgba(38, 34, 45, .90);

    }

  }
  .ribbing{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__menu {
      h2 {
        margin-bottom: 20px;
      }
    }
    &__list {
      input {
        position: absolute;
      }
      label.active {
        .ribbing__title {
          color: $color3;
        }
        svg circle, svg path{
          stroke: $color3;
          transition: all .3s;
        }
      }
      label:hover {
        .ribbing__title {
          color: $color3;
        }
        svg circle, svg path {
          stroke: $color3;
          transition: all .3s;
        }
      }
    }
    .ribbing__title {
      color: $color3;
      transition: all .3s;
    }
    .ribbing-icon {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 20%;
      .thumbnail__image {
        margin: 0 auto;
        transition: all .4s;
      }
    }
  }

  .menu-open-button{
    img{
      padding: 12px;
    }
  }

  .menu-button{
    a{
      img{
        padding-top: 18px;
        max-width: 20px;
      }
    }
  }

  .thumbnail{
    &__slider{
      label:hover{
        .thumbnail__image{
          transition: all .4s;
          border-color: $color3;
          transform: scale(0.9);
        }
        .thumbnail__title {
          color: $color3;
        }
      }
      label.active{
        .thumbnail__image{
          transition: all .4s;
          border-color: $color3;
        }
        .thumbnail__title {
          color: $color3;
        }
      }
    }
    &__box{
      cursor: pointer;
      &:hover{
        .thumbnail__image{
          border-color: $color3;
          transform: scale(0.9);
        }
        .thumbnail__title {
          color: $color3;
        }
      }
      &.active{
        .thumbnail__image{
          border-color: $color3;
        }
        .thumbnail__title {
          color: $color3;
        }
      }
    }
  }
  .ribbing__menu{
    display: block;
    li{
      text-align: center;
    }
    h2{
      font-size: 20px;
      color: $color3;
      text-align: center;
      text-transform: uppercase;
    }
    .ribbing__title {
      font-size: 10px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 13px;
    }

  }
  .menu{
    min-height: 270px;
    &__link{
      color: #979798;
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 13px;
      text-decoration: none;
      position: absolute;
      width: 100%;
      bottom: 10%;
      &:hover{
        color: #fff;
        transition: all .4s;
      }
    }
    &__header{
      font-size: 24px;
      color: $color3;
      text-transform: uppercase;
      line-height: 0.833;
      text-align: left;
      display: block;
    }
    li {
      width: 100%;
      display: block;
      padding: 0;
      color: #979798;
      font-size: 16px;
      font-weight: 200;
      position: relative;
      outline: 0;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        background: rgba(55, 49, 66, 0.41);
        color: #fff;
        // &:after {
        //   opacity: 0;
        // }
        span {
          left: 5px;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 40px;
        height: 1px;
        background: #fff;
        opacity: 0.25;
        position: absolute;
        bottom: 0;
        left: 35px;
        transition: all 0.2s ease;
      }
      // &--hover {
      //   &:after {
      //     opacity: 0;
      //   }
      // }
      &:first-child {
        cursor: initial;
        &:hover {
          background: none;
          span {
            left: 0;
          }
        }
        &:after {
          display: none;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      span {
        position: relative;
        left: 0;
        transition: all 0.2s ease;
        width: 100%;
        height: 100%;
        display: block;
        padding: 33px 35px;
        &.active {
          color: #fff;
        }
      }
    }
  }
  .thumbnail{
    input[type=radio]{
      visibility: hidden;
    }
    label{
      display: block;
    }
    position: relative;
    .thumbnail-next{
      position: absolute;
      display: block;
      width: 23px;
      height: 13px;
      background-image: url(../img/arrow-up.png);
      background-repeat: no-repeat;
      background-position: center;
      top:-50px;
      left: calc(50% - 20px);
      padding: 20px;
      background-color: transparent;
      border: 0;
      transition: .4s;
      &:focus{
        outline: none;
      }
      &:hover{
        transition: .4s;
        transform: scale(.9);
      }
      &.slick-disabled{
        opacity: 0.05;
        transition: .4s;
      }
    }
    .thumbnail-prev{
      position: absolute;
      display: block;
      width: 23px;
      height: 13px;
      background-image: url(../img/arrow-down.png);
      background-repeat: no-repeat;
      background-position: center;
      bottom: -50px;
      left: calc(50% - 20px);
      padding: 20px;
      background-color: transparent;
      border: 0;
      transition: .4s;
      &:focus{
        outline: none;
      }
      &:hover{
        transition: .4s;
        transform: scale(.9);
      }
      &.slick-disabled{
        opacity: 0.05;
        transition: all .4s;
      }
    }
    &__slider{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .slick-slide:focus{
        outline: none;
      }
      &>div{
        display: inline-block;
      }
    }
    &__title{
      font-size: 12px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      transition: all .3s;
      min-height: 32px;
    }
    &__image{
      padding: 3px;
      border: 2px solid #fff;
      border-radius: 100%;
      margin: 0 auto;
      max-width: 75px;
      transition: all .3s;
    }
  }
}
