$c1: #888;
$c2: #fff;
$c3: #888;

.spinner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s;
  &.active {
    background: rgba(0, 0, 0, 0.50);
    .jaf-loader {
      display: block;
    }
  }
}
.jaf-loader {
  display: none;
  width: 80px;
  animation: loader 3s ease-in infinite, spin 1s linear infinite;
  transform-origin: 50% 71%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
}
#loading_spinner {
  display: none;
  filter: saturate(3);
  width: 0.1px;
  height: 0.1px;
  border: 40px solid transparent;
  border-radius: 5px;
  animation: loader 3s ease-in infinite, spin 1s linear infinite;
  z-index: 11;
}
#loading_spinner::before {
  filter: saturate(0.3);
  display: block;
  position: absolute;
  z-index: -1;
  margin-left: -40px;
  margin-top: -40px;
  content:'';
  height:0.1;
  width:0.1;
  border: 40px solid transparent;
  border-radius: 5px;
  animation: loader 2s ease-in infinite reverse, spin 0.8s linear infinite reverse;
}
#loading_spinner::after {
  display: block;
  position: absolute;
  z-index: 2;
  margin-left: -10px;
  margin-top: -10px;
  content:'';
  height:20px;
  width:20px;
  border-radius: 20px;
  background-color: white;
}
@keyframes loader {
  0% {border-bottom-color:transparent;border-top-color:$c1}
  25% {border-left-color:transparent;border-right-color:$c2}
  50% {border-top-color:transparent;border-bottom-color:$c3}
  75% {border-right-color:transparent;border-left-color:$c2}
  100% {border-bottom-color:transparent;border-top-color:$c1}
}
@keyframes spin {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(-360deg)}
}

.render-wrapper{
  margin:0;
  height:100%;
  overflow:hidden;
  .render-image{
    min-height:100%;
    min-width:100%;
    max-width: 1920px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .render-photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/img/background.jpg');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    z-index: 2;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
  }
  .render-photo2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/img/background.jpg');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    z-index: 1;
  }
}


$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1{
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0{
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}

@function sin($angle){
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle){
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
  }
  @return $cos;
}

@function tan($angle){
  @return sin($angle) / cos($angle);
}


//vars
$fg: #EA580C;
$bg: #EA580C;
$pi:3.14;

//config
$menu-items:3;
$open-distance:70px;
$opening-angle:$pi - 1.2;

%ball{
  background:$fg;
  border-radius:100%;
  width:55px;
  height:55px;
  margin-left:-40px;
  position:absolute;
  top:0px;
  color:white;
  text-align:center;
  line-height:55px;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
}
.menu-open{
  display:none;
}
.menu-item{
  @extend %ball;
}
.hamburger{
  $width:25px;
  $height:3px;
  width:$width;
  height:$height;
  background:white;
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-$width/2;
  margin-top:-$height/2;
  transition:transform 200ms;
}
$hamburger-spacing:8px;
.hamburger-1{
  transform:translate3d(0,-$hamburger-spacing,0);
}
.hamburger-2{
  transform:translate3d(0,0,0);
}
.hamburger-3{
  transform:translate3d(0,$hamburger-spacing,0);
}
.menu-open:checked+.menu-open-button{
  .hamburger-1{
    transform:translate3d(0,0,0) rotate(45deg);
  }
  .hamburger-2{
    transform:translate3d(0,0,0) scale(0.1,1);
  }
  .hamburger-3{
    transform:translate3d(0,0,0) rotate(-45deg);
  }
}
.menu-button{
  $width: 275px;
  $height: 250px;
  position:fixed;
  right: -231px;
  top: 30px;
  padding-top: 20px;
  width: $width;
  height: $height;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
  z-index: 10;
}


.menu-item{
  transition: all .4s;
  &:hover{
    background:#26222d;
    color:$fg;
    transition: all .4s;
    cursor: pointer;
  }
  @for $i from 1 through $menu-items{
    &:nth-child(#{$i+2}){
      transition-duration:10ms+(60ms*($i));
    }
  }
}

.menu-open-button{
  @extend %ball;
  z-index:2;
  transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration:400ms;
  transform:scale(1.1,1.1) translate3d(0,0,0);
  cursor:pointer;
}
.menu-open-button:hover{
  transform:scale(1.2,1.2) translate3d(0,0,0);
}
.menu-open:checked+.menu-open-button{
  transition-timing-function:linear;
  transition-duration:200ms;
  background-color: #26222d;

}

.menu-open:checked~.menu-item{
  transition-timing-function:cubic-bezier(0.935, 0.000, 0.340, 1.330);
  @for $i from 1 through $menu-items{
    $angle:(($pi - $opening-angle / 1.1))+(($opening-angle/($menu-items - 1))*($i - 1));

    &:nth-child(#{$i+2}){
      transition-duration:80ms+(80ms*$i);
      transform:translate3d(cos($angle)*$open-distance,sin($angle)*$open-distance,0);
    }
  }
}

.cls-1, .cls-2 {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
}

.cls-2 {
  fill-rule: evenodd;
}

.ribbing__list{
  input{
    visibility: hidden;
  }
}

.ribbing__natural {
  transform: scale(.65);
}

.names-wrapper {
  position: absolute;
  bottom: 45px;
  right: 19px;
  text-align: right;
  z-index: 11;
  opacity: 1;
  transition: all 0.3s;
  background: $color3;
  color: #fff;
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: right;
  &--closed {
    opacity: 0;
    right: -200px;
    transition: all 0.3s;
  }
  .name-wrapper {

  }
  .facade-name {

  }
}

.portrait-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #26222d;
  color: #fff;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .tilt-logo {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
  }
  .tilt-wrapper {
    .tilt-icon {
      position: relative;
      width: 48px;
      // height: 60px;
      margin: 0 auto;
      .rotate-front {
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 46px;
        @include tablet {
          display: block;
        }
      }
    }
    .tilt-desc {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 300;
      text-align: center;
      margin: 20px;
      letter-spacing: 1px;
    }
  }
  // &__title {
  //   text-align: center;
  //   font-size: 22px;
  // }
}

.ie-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 34, 45, 0.98);
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
  &.open {
    display: flex;
  }
  p {
    color: #fff;
    font-size: 18px;
    &:first-child {
      color: $color3;
    }
    a {
      color: #eee;
    }
  }
}

.version {
  color: #75707d;
  font-size: 11px;
  position: absolute;
  bottom:15px;
  left: 0;
  width: 100%;
  text-align: center;
}

// .portrait-alert {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 20;
//   width: 100%;
//   height: 100%;
//   background: #1c1b1d;
//   display: none;

//   -webkit-flex-direction: column;
//   flex-direction: column;
//   -webkit-align-items: center;
//   align-items: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   @media  (max-device-width: 480px) and (orientation: landscape) {
//     display: -webkit-flex;
//     display: flex;
//   }
// }

